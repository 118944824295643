export default {
  addProductToCart(state, payload) {
    const productData = payload;
    const productInCartIndex = state.cart.findIndex(
      (ci) => ci.id === productData.id
    );
    if (productInCartIndex >= 0) {
      state.cart[productInCartIndex].qty++;
    } else {
      const newItem = {
          name: productData.name,
          desc: productData.desc,
          price: productData.price,
          img: productData.img,
          qty: productData.qty,          
      };
      state.cart.push(newItem);
    }
    state.qty++;
    state.total += productData.price;
  },

  updateCart(state, payload) {
    const cartItemIndex = state.cart.findIndex(
      (item) => item.id === payload.id
    );
    if (cartItemIndex >= 0) {
      state.cart[cartItemIndex].name = payload.name;
      state.cart[cartItemIndex].desc = payload.desc;
      state.cart[cartItemIndex].price = payload.price;
      state.cart[cartItemIndex].img = payload.img;
      state.cart[cartItemIndex].qty = payload.qty;
      state.cart[cartItemIndex].pay_method = payload.pay_method;
    }
    // calculate total and quantity again
    state.qty = state.cart.reduce((total, item) => total + item.qty, 0);
    state.total = state.cart.reduce((total, item) => total + item.qty * item.price, 0);
  },
};