import { createStore } from "vuex";

import rootMutations from "../services/mutations.js";
import rootActions from "../services/actions.js";
import rootGetters from "../services/getters.js";

// cart
const store = createStore({
  state() {
    return {
      cart: [],
      total: 0,
      qty: 0,
      products: [],
      mutations: rootMutations,
      actions: rootActions,
      getters: rootGetters,
    }
  }
});

export default store;