import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faEye,
  faEyeSlash,
  faHeart as faRegularHeart,
  faSquareMinus,
} from "@fortawesome/free-regular-svg-icons";

import {
  faPrint,
  faLaptopFile,
  faTags,
  faClockRotateLeft,
  faTrash,
  faPencil,
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faArrowLeft,
  faHeart as faSolidHeart,
  faCircleMinus,
  faCirclePlus,
  faShoppingCart,
  faMoneyBills,
  faQrcode,
  faReceipt,
  faBookmark,
  faMagnifyingGlass,
  faLocationDot,
  faPhone,
  faShareNodes,
  faCheck,
  faChevronLeft,
  faMotorcycle,
  faBagShopping,
  faUtensils,
  faIceCream,
  faCookieBite,
  faPercent,
  faFilter,
  faX,
  faSignOut,
  faUsers,
  faWallet,
  faDoorOpen,
  faUserGear,
  faUserTie,
  faFloppyDisk,
  faDoorClosed,
  faInbox,
  faCalendarCheck,
  faTableCells,
  faClipboard,
  faTicket,
  faShop,
  faCircleInfo,
  faMars,
  faVenus,
  faRightFromBracket,
  faPlus,
  faArrowsRotate,
  faComments,
  faTimes,
  faPaperclip,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

import { faGoogle } from "@fortawesome/free-brands-svg-icons";

library.add(
  faPrint,
  faLaptopFile,
  faSignOut,
  faGoogle,
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faArrowLeft,
  faSolidHeart,
  faRegularHeart,
  faSquareMinus,
  faCircleMinus,
  faCirclePlus,
  faShoppingCart,
  faMoneyBills,
  faQrcode,
  faReceipt,
  faBookmark,
  faMagnifyingGlass,
  faLocationDot,
  faPhone,
  faShareNodes,
  faCheck,
  faChevronLeft,
  faMotorcycle,
  faBagShopping,
  faUtensils,
  faIceCream,
  faCookieBite,
  faPercent,
  faFilter,
  faX,
  faPencil,
  faTrash,
  faClockRotateLeft,
  faTags,
  faUsers,
  faWallet,
  faDoorOpen,
  faUserGear,
  faUserTie,
  faFloppyDisk,
  faDoorClosed,
  faInbox,
  faCalendarCheck,
  faTableCells,
  faClipboard,
  faTicket,  
  faShop,
  faCircleInfo,
  faMars,
  faVenus,
  faEye,
  faRightFromBracket,
  faEyeSlash,
  faPlus,
  faArrowsRotate,
  faComments,
  faTimes,
  faPaperclip,
  faPaperPlane
);

export { FontAwesomeIcon };
