export default {
    products(state) {
      return state.products;
    },
    totalSum(state) {
      return state.total;
    },
    quantity(state) {
      return state.qty;
    },
    cart(state) {
      return state.cart;
    }
};